<template>
  <section id="sectionFooterNew" class="footer-section-new">
    <div class="container-fluid footer-background" style="background-color: #ffffff">
      <div class="container">
        <div class="col-md-12">
          <div class="footer-heading">Get in touch</div>
        </div>
      </div>
      <div class="footer-social-links">
        <a href="https://www.facebook.com/Kips.edu.pk" target="_blank">
          <i class="fab fa-facebook-f pl25"></i>
        </a>
        <a href="https://www.instagram.com/kips.official/" target="_blank2">
          <i class="fab fa-instagram" id="insta" aria-hidden="true"></i>
        </a>
        <a href="https://www.youtube.com/channel/UCnUoF97dDpa4aslXjt30GeQ" target="_blank3">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
      <div class="uan-number">
        <div>support@kipslms.com</div>
        <div class="pt10">(042) 111 547 775</div>
        <div class="contact-timings">(Mon - Sat, 10AM - 4PM)</div>
      </div>
    </div>
    <div class="footer-kips-tagline">© 2020 KIPS LMS</div>
  </section>
</template>
<script>
export default {};
</script>
