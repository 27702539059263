import { appConstants } from "../../../../common/constants";
import { checkout as checkoutStoreKeys } from "../../../keys";
import { clientStore as store } from "../../../../store";
import {servicesFactory} from "../../../../services/serviceFactory.js";
import cryptojs from "../../../../plugins/crypto";

export default {
  namespaced: true,
  state: {
    cartId: "",
    cart: [],
  },
  getters: {
    totalRecordInCart(state) {
      return state.cart.length;
    },
    cart(state) {
      return state.cart;
    },
    totalAmount(state) {
      return state.cart.reduce((acc, product) => acc + product.Amount, 0);
    },
    isCourseExist(state) {
      return (sosId) =>
        state.cart.some(function(field) {
          return field.SOSId == sosId;
        });
    },
    GetSelectedSOSFromCart(state) {
      return (sosId) =>
        state.cart.find(function(sos) {
          if (sos.SOSId == sosId) {
            return sos;
          }
        });
    },
    getCartId(state) {
      return state.cartId;
    },
    selectedSOS: (state) => (SOSId) => {
      return state.cart.find((item) => item.SOSId == SOSId)
    }
  },
  mutations: {
    add(state, model) {
      if (appConstants.enums.PaymentOption.Trial == model.PaymentOption) {
        state.cart = [];
      }

      if (state.cart && state.cart.length) {
        if (
          state.cart.some(
            (x) => x.PaymentOption == appConstants.enums.PaymentOption.Trial
          )
        ) {
          state.cart = [];
        }
      }

      state.cart.push(model);
    },
    remove(state, sosId) {
      let i = state.cart.map((product) => product.SOSId).indexOf(sosId);

      if (i !== -1) {
        state.cart.splice(i, 1);
      }
    },
    setCartId(state, payload) {
      state.cartId = payload.CartId;
    },
    setCart(state) {
      state.cart = []
    }
  },
  actions: {
    addToCart({ commit, dispatch, getters }, model) {
      if (getters.isCourseExist(model.data.SOSId)) {
        // check isCourseExist if exist then throw exception course already exist
        if (process.env.VUE_ENV === "client") {
          return Promise.reject("Course already exist");
        }
      }

      commit("add", model.data);

      store.dispatch(
        `${checkoutStoreKeys.namespace}/${checkoutStoreKeys.actions.checkout_cart}`
      );
    },
    removeFromCart({ state, commit }, model) {
      commit("remove", model.data);
    },
    checkOut({ commit, getters }) {
      let param = {
        data: {
          CartItems: getters.cart,
        },
        config: {
          loader: {
            show: true,
            id: "checkoutLoaderId",
          },
        },
      };
      return servicesFactory.createService(servicesFactory.serviceType.enums.services.lmsService).cartService.saveCart(param).then((response) => {
        commit("setCartId", response.data);
        let referrer = cryptojs.UTF.encrypt(process.env.VUE_APP_URL)
        window.location.href = `${process.env.VUE_APP_PORTAL_URL}/invoice/checkOut/${getters.getCartId}/${referrer}`;
      });
    },
    resetCart({ commit }) {
      commit('setCart')
    }
  },
};
