<template>
  <div>
    <section class="banner-section">
      <!-- <img class="spiral-img" src="/assets/images/homepage/header-spiral.svg" /> -->
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="dbw-left">
              <h1 class="heading">Learn<br /><span>without</span><br />bounds</h1>
              <p class="hero-para">
                Break the shackles of time and <br />
                space and learn wherever you are,<br />
                whenever you feel like it.
              </p>
              <!-- <button v-b-modal="'banner-video-modal'" class="btn btn-white">
                Virtual Tour
              </button> -->
            </div>
          </div>
          <div class="col-md-7">
            <div class="homepage-hero-visual">
              <div class="visuals-cluster-container">
                <img class="lazy visual-primary loaded" src="/assets/images/homepage/Home1-Back.svg" />
                <div class="visual-secondary-top bounce animateImg">
                  <img class="lazy grow1 loaded" alt="" src="/assets/images/homepage/Home1-Front1-1.png" />
                </div>

                <div class="visual-secondary-middle animateImg">
                  <img class="lazy bounce2 loaded" alt="" src="/assets/images/homepage/Home1-Front1-2.png" />
                </div>
                <div class="visual-secondary-bottom animateImg">
                  <img class="lazy bounce3 loaded" alt="" src="/assets/images/homepage/Home1-Front1-3.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="programs-offer" id="sectionPrograms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="head">What we offer</div>
            <p>KIPS LMS is an online learning management system for a wide range of academic programs.</p>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <!-- <div class="col-md-2"></div> -->
          <div class="col-md-6">
            <div class="program-box first-program">
              <!-- <img src="/assets/images/homepage/round-shape.svg"/> -->
              <div class="program-left">
                <img src="/assets/images/homepage/Program-icon-1.svg" alt="" loading="lazy"/>
                <div class="left-head">Classes</div>
              </div>

              <div class="program-right">
                <!-- <class-menu></class-menu> -->
                <ul>
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>9th</li>
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>10th</li>
                  <!-- <li @click="redirectToProgramDetail">
                    <span class="blue-dot"></span>O Level
                  </li> -->
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>1st Year</li>
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>2nd Year</li>
                  <!-- <li @click="redirectToProgramDetail">
                    <span class="blue-dot"></span>A Level
                  </li> -->

                  <!-- <li
                    v-on:click="updateSelectedClass(item)"
                    class="header_subMenuItem"
                    v-for="(item, index) in classes"
                    :key="index"
                  >
                    <span class="blue-dot"></span>
                    <a :title="item.Name" href="javascript:void(0)">{{
                      item.Name
                    }}</a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="program-box second-program">
              <div class="program-left">
                <img src="/assets/images/homepage/Program-icon-2.svg" alt="" loading="lazy"/>
                <div class="left-head">Exams</div>
              </div>

              <div class="program-right">
                <!-- <exam-menu></exam-menu> -->
                <ul>
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>Medical</li>
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>Engineering</li>
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>General Prep</li>
                  <li @click="redirectToProgramDetail"><span class="blue-dot"></span>Foreign Tests</li>
                  <!-- <li @click="redirectToProgramDetail">
                    <span class="blue-dot"></span>Public
                  </li> -->
                </ul>
                <!-- <ul v-for="(sosCategory, index) in sosCategories" :key="index">
                  <li v-on:click="redirectToExam(sosCategory)" class="header_subMenuTitle">
                    <span class="blue-dot"></span>
                    <a
                      :title="sosCategory.CourseGroupCategoryName"
                      href="javascript:void(0)"
                      >{{ sosCategory.CourseGroupCategoryName }}</a
                    >
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
          <!-- <div class="col-md-2"></div> -->
        </div>
      </div>
    </section>

    <section class="practice-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="head">Practice Today, Perform Tomorrow</div>
            <p>We offer a number of features for a comprehensive online learning experience.</p>
          </div>
        </div>
        <div class="row column-order-reverse">
          <div class="col-md-8">
            <img src="/assets/images/homepage/Feature-img.png" alt="" loading="lazy"/>
          </div>
          <div class="col-md-4">
            <div class="accordion" role="tablist">
              <div>
                <div block v-b-toggle.accordion-1 class="small-heading-white">e-Lectures</div>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Watch video lectures delivered by our extremely competent and experienced faculty anywhere, anytime.</p>
                </b-collapse>
              </div>

              <div>
                <div block v-b-toggle.accordion-2 class="small-heading-white">Readings</div>
                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">For a comprehensive learning experience, you have access to greatly useful texts to go with the lectures.</p>
                </b-collapse>
              </div>

              <div>
                <div block v-b-toggle.accordion-3 class="small-heading-white">Adaptive Testing</div>
                <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">The questions that appear in your tests are categorized as easy, medium, and hard according to their difficulty levels.</p>
                </b-collapse>
              </div>

              <div>
                <div block v-b-toggle.accordion-4 class="small-heading-white">Search</div>
                <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Find videos and readings related to your search.</p>
                </b-collapse>
              </div>

              <div>
                <div block v-b-toggle.accordion-5 class="small-heading-white">Teacher Support</div>
                <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Chat with subject experts to find instant answers to your queries.</p>
                </b-collapse>
              </div>

              <div>
                <div block v-b-toggle.accordion-6 class="small-heading-white">Stats</div>
                <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">View the extent to which you have completed the course of each subject in each of your programs, along with your result in each test.</p>
                </b-collapse>
              </div>

              <div>
                <div block v-b-toggle.accordion-7 class="small-heading-white">Leaderboard</div>
                <b-collapse id="accordion-7" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">View the top positions in your program among all LMS users of the current academic year.</p>
                </b-collapse>
              </div>
              <div>
                <div block v-b-toggle.accordion-8 class="small-heading-white">Notes</div>
                <b-collapse id="accordion-8" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Jot down notes while watching videos or accessing readings and review them whenever you need.</p>
                </b-collapse>
              </div>
              <div>
                <div block v-b-toggle.accordion-9 class="small-heading-white">Bookmarks</div>
                <b-collapse id="accordion-9" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Bookmark the videos and readings you deem important to run through them afterwards.</p>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="new-features">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="head">Added Features</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="new-feature-box">
              <img src="/assets/images/homepage/New-feature-1.svg" alt="" loading="lazy"/>
              <div class="sub-head">Assignments</div>
              <div class="detail">Receive assignments and submit them to be checked by your teachers.</div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="new-feature-box">
              <img src="/assets/images/homepage/New-feature-2.svg" alt="" loading="lazy"/>
              <div class="sub-head">Live Lectures</div>
              <div class="detail">Get your doubts removed and your concepts cleared by meeting your tutors virtually in live interactive sessions.</div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="new-feature-box">
              <img src="/assets/images/homepage/New-feature-3.svg" alt="" loading="lazy"/>
              <div class="sub-head">Notifications</div>
              <div class="detail">Get the latest information related to your course and remain updated with upcoming events and changes in the scheme of studies or the system.</div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="new-feature-box">
              <img src="/assets/images/homepage/New-feature-4.svg" alt="" loading="lazy"/>
              <div class="sub-head">Miscellaneous Videos</div>
              <div class="detail">Watch useful videos other than those directly related to your course, such as counselling videos and important messages from the management.</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="faq-section" id="sectionFaq">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img src="/assets/images/homepage/round-shape-green.svg" alt="" loading="lazy"/>
            <div class="faq-text">
              <div class="head">
                Frequently Asked
                <br />
                Questions
              </div>
              <div class="subhead">Helpful tips to benefit from KIPS LMS</div>
              <router-link class="btn btn-blue see-all-faqs" to="/Faq">View All</router-link>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-7">
            <div class="accordion" role="tablist">
              <div class="accordion-parent">
                <div block v-b-toggle.accordion-1 class="small-heading-white">
                  <span class="accordion-icon"></span>
                  Which browser is recommended for using KIPS LMS?
                </div>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">The updated version of Google Chrome is recommended for KIPS LMS. However, Microsoft Edge, Mozilla FireFox and Safari also support KIPS LMS.</p>
                </b-collapse>
              </div>

              <div class="accordion-parent">
                <div block v-b-toggle.accordion-2 class="small-heading-white">
                  <span class="accordion-icon"></span>
                  How do I attempt tests, watch videos, and view readings?
                </div>
                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">You can find week-wise readings, videos and tests from course activities or you can find readings and videos via search bar.</p>
                </b-collapse>
              </div>

              <div class="accordion-parent">
                <div block v-b-toggle.accordion-3 class="small-heading-white">
                  <span class="accordion-icon"></span>
                  How do I access teacher support?
                </div>
                <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">You can access chat from the Subject SOS page or through the Teacher Support link in the left menu.</p>
                </b-collapse>
              </div>

              <div class="accordion-parent">
                <div block v-b-toggle.accordion-4 class="small-heading-white">
                  <span class="accordion-icon"></span>
                  How do I contact LMS support?
                </div>
                <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Send an email to support@kipslms.com. Your queries related to the LMS will be catered within 48 hours.</p>
                </b-collapse>
              </div>

              <div class="accordion-parent">
                <div block v-b-toggle.accordion-5 class="small-heading-white">
                  <span class="accordion-icon"></span>
                  How can I view my progress in my programs?
                </div>
                <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                  <p class="content-para">You can view a graphical representation of your progress by clicking on the Stats link in the left menu.</p>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonial-section" id="sectionTestimonial">
      <div class="container">
        <div class="flex-container">
          <div class="testimonial-card blue-card">
            <p>When I first heard about this online learning system, I had doubts about its efficacy, but when I used it, I was awed by its comprehensiveness, user-friendliness and adaptivity.</p>
            <div class="info">
              <img height="55" width="55" src="/assets/images/homepage/testimonials/testimonial-1.png" alt="" loading="lazy"/>
              <div class="info-description">
                <div class="head">Hafiz M Ibrahim</div>
                <div class="sub-head">1st Position, PIEAS</div>
              </div>
            </div>
          </div>

          <div class="testimonial-play-card" v-b-modal="'testimonialFirst-video-modal'">
            <div class="left">
              <img class="" src="/assets/images/homepage/testimonials/testimonial-2.png" alt="" loading="lazy"/>
            </div>
            <div class="right">
              <img class="yellow-half" src="/assets/images/homepage/Yellow-half-circle.png" alt="" loading="lazy"/>
              <img height="50" width="50" class="play-icon" src="/assets/images/homepage/play-icon.png" alt="" loading="lazy"/>
              <div class="head">Farzeen Hamid</div>
              <div class="sub-head">1st Position, NUMS</div>
            </div>
          </div>

          <div class="testimonial-card green-card">
            <p>I wouldn't have been able to achieve what I have without the Online Teacher Support I received at KIPS LMS. The teachers were easily approachable and very helpful.</p>
            <div class="info">
              <img height="55" width="55" src="/assets/images/homepage/testimonials/testimonial-5.png" alt="" loading="lazy"/>
              <div class="info-description">
                <div class="head">Liaqat Ali</div>
                <div class="sub-head">1st Position, ETEA Engg</div>
              </div>
            </div>
          </div>

          <div class="testimonial-play-card" v-b-modal="'testimonialSecond-video-modal'">
            <div class="left">
              <img class="" src="/assets/images/homepage/testimonials/testimonial-3.png" alt="" loading="lazy"/>
            </div>
            <div class="right">
              <img class="yellow-half" src="/assets/images/homepage/Yellow-half-circle.png" alt="" loading="lazy"/>
              <img height="50" width="50" class="play-icon" src="/assets/images/homepage/play-icon.png" alt="" loading="lazy"/>
              <div class="head">Noman Ahmed</div>
              <div class="sub-head">1st Position, Pre-Engg</div>
            </div>
          </div>

          <div class="testimonial-card blue-card mt-100">
            <p>While preparing for MUST, I felt that I needed a lot more time than that I had spent at my academy to be well-prepared. KIPS LMS was just the tool I needed to succeed in the test.</p>
            <div class="info">
              <img height="55" width="55" src="/assets/images/homepage/testimonials/testimonial-4.png" alt="" loading="lazy"/>
              <div class="info-description">
                <div class="head">Kashaf Aziz</div>
                <div class="sub-head">1st Position, MUST</div>
              </div>
            </div>
          </div>

          <div class="testimonial-play-card" v-b-modal="'testimonialThird-video-modal'">
            <div class="left">
              <img class="" src="/assets/images/homepage/testimonials/testimonial-6.png" alt="" loading="lazy"/>
            </div>
            <div class="right">
              <img class="yellow-half" src="/assets/images/homepage/Yellow-half-circle.png" alt="" loading="lazy"/>
              <img height="50" width="50" class="play-icon" src="/assets/images/homepage/play-icon.png" alt="" loading="lazy"/>
              <div class="head">Usman Ahmad</div>
              <div class="sub-head">1st Position, MDCAT</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="download-section" id="sectionDownloadApp">
      <div class="container">
        <img class="spiral-img" src="/assets/images/homepage/header-spiral.svg" alt="" loading="lazy"/>
        <img class="app-img" src="/assets/images/homepage/Mobile-img.png" />
        <div class="head">Download the App today!</div>
        <p>With KIPS LMS, you have your future in your own hands, or at least the preparation for it.</p>
        <div class="app-btns">
          <a href="https://play.google.com/store/apps/details?id=com.kips.kipslms" class="btn app-btn">
            <img src="/assets/images/homepage/playstore.png" alt="" loading="lazy"/>
            Playstore
          </a>
          <a href="https://apps.apple.com/pk/app/kips-lms/id1471602811" class="btn app-btn">
            <img src="/assets/images/homepage/app-store.png" alt="" loading="lazy"/>
            Appstore
          </a>
        </div>
      </div>
    </section>

    <div class="container">
      <section class="footer-section">
        <div class="footer-bg">
          <div class="footer-bg-inner"></div>
        </div>
        <div class="row justify-content-between plr12">
          <div class="col-md-3 talignc-responsive">
            <a href="/" class="footer-link"><img src="assets/images/homepage/LMS-logo-white.svg" class="footer-logo" alt="" loading="lazy"/> <span class="footer-site-name">KIPS LMS</span></a>
          </div>
          <!-- <div class="col-md-2"></div> -->
          <div class="col-md-7 mtb5 talignc-responsive">
            <div class="footer-social-btm">
              <span class="footer-mail"> (042) 111 547 775 </span>
              <span class="footer-mail"> support@kipslms.com </span>
              <div class="social-icons-area">
                <a href="https://www.facebook.com/Kips.edu.pk" target="_blank"
                  ><span class="social-icons"><i class="fab fa-facebook-f"></i></span
                ></a>
                <a href="https://twitter.com/kipsofficial" target="_blank2"
                  ><span class="social-icons"><i class="fab fa-twitter"></i></span
                ></a>
                <a href="https://www.instagram.com/kips.official/" target="_blank3"
                  ><span class="social-icons"><i class="fab fa-instagram pr0"></i></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <b-modal ref="modal-home" id="banner-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/5TxgnAQXISs?rel=0&amp;showinfo=0&amp;autoplay=1" allowfullscreen></b-embed>
    </b-modal>

    <b-modal ref="modal-home" id="testimonialFirst-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/1NtMrenaP7M?rel=0&amp;showinfo=0&autoplay=1" allowfullscreen></b-embed>
    </b-modal>

    <b-modal ref="modal-home" id="testimonialSecond-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/Sy3TKZL4CNk?rel=0&amp;showinfo=0&autoplay=1" allowfullscreen></b-embed>
    </b-modal>

    <b-modal ref="modal-home" id="testimonialThird-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/HdsJt_zOS7s?rel=0&amp;showinfo=0&autoplay=1" allowfullscreen></b-embed>
    </b-modal>
  </div>
</template>
<script>
import classMenu from "../views/course/class/menu";
import examMenu from "../views/course/exam/menu";
import { appConstants } from "../common/constants";
import { mapGetters, mapActions } from "vuex";
import {
  exam as examStoreKeys,
  classes as classesStoreKeys,
  course as courseStoreKeys,
} from "../store/keys";
export default {
    metaInfo() {
    return {
      title: "KIPS LMS: Learn Without Bounds",
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      }
    };
  },
  components: {
    classMenu: classMenu,
    examMenu: examMenu,
  },
  data() {
    return {};
  },
  asyncData({ store, route }) {
    return Promise.resolve();
  },
  created() {},

  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.hash) {
      var elm = this.$route.hash.slice(1);
      this.scrollToElement(elm);
    }
  },
  computed: {
    ...mapGetters(classesStoreKeys.namespace, classesStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(examStoreKeys.namespace, examStoreKeys.getters),
  },
  methods: {
    ...mapActions(classesStoreKeys.namespace, classesStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    updateSelectedClass(item) {
      this.reset_SelectedCourseInfo({});
      this.update_SelectedClass({
        data: {
          program: { id: item.ProgramId, name: item.Name },
          programCategory: {
            id: appConstants.enums.programCategory.Class,
            name: "",
          },
        },
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          this.RouteTo(
            "/class/" +
              this.RemoveSpecialChars(item.Name) +
              "-" +
              item.ProgramId
          );
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    redirectToExam(item) {
      this.RouteTo(
        "/exam/" + this.RemoveSpecialChars(item.CourseGroupCategoryName)
      );
    },
    redirectToProgramDetail(event) {
      let item = event.target.textContent;
      this.RouteTo("/programDetail/" + this.RemoveSpecialChars(item));
    },
    hideModal() {
      this.$refs["modal-home"].hide();
    },
    getFileAbsoluteCdnPath(fileRelativePath) {
      return process.env.VUE_APP_CDN_BASE_URL + fileRelativePath;
    },
  },
};
</script>
