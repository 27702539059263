import CourseService from "./services/course.service";
import CartService from "./services/cart.service";
import ProgramService from "./services/program.service";
export default class lmsService {
  get courseService() {
    if (!this._CourseService) {
      this._CourseService = new CourseService();
    }
    return this._CourseService;
  }
  get cartService() {
    if (!this._CartService) {
      this._CartService = new CartService();
    }
    return this._CartService;
  }
  get programService() {
    if (!this._ProgramService) {
      this._ProgramService = new ProgramService();
    }
    return this._ProgramService;
  }
}
