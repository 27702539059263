import BaseService from "../base/base.service";
import requestBus from '../../requestBus.js'
export default class LmsBaseService extends BaseService {
  constructor() {
    let refererHeader = {};
    if (!(process.env.VUE_ENV === 'client')) {
      refererHeader = {
        "referer": `${requestBus.$context.request.headers.protocol}://${requestBus.$context.request.headers.host}`
      }
    }
    super({
      reqHeader: {
        "content-type": "application/json; charset=utf-8",
        "api-security-key": process.env.VUE_APP_API_SECURITY_KEY,
        ...refererHeader
      },
      baseURL: process.env.VUE_APP_API_URL
    });
  }
}