import LmsBaseService from '../lmsBase.service';
import {
    apiEndPoints
} from '../constants'

export default class CartService extends LmsBaseService {
    constructor() {
        super({})
    }
    saveCart(param) {
        return this.post(apiEndPoints.cart.saveCart , true, param.data, param.config);
    }
}