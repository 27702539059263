export const apiEndPoints = Object.freeze({
  course: {
    getClassCourses: '/CourseService/GetCourses',
    getClasses: '/CourseService/GetClasses',
    getClassBoards: '/CourseService/GetClassBoards',
    getClassGroups: '/CourseService/GetClassGroups',
    getExams: '/CourseService/GetExams',
    getCourseDetail: '/CourseService/GetCourseDetail',
    getExamCourses: '/CourseService/GetExamCourses',
  },
  cart: {
    saveCart: '/CartService/SaveCart'
  }
});
