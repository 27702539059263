<template>
  <div class="landingpage-body" v-show="displayUI">
    <div @click="gotToTop" class="btn-goto-top" v-bind:class="{ showGoToTopBtn: scrolled }">
      <i class="fas fa-angle-up" aria-hidden="true"></i>
    </div>

    <app-header :scrolled="scrolled"></app-header>
    <div class="landing-page-content">
      <transition-page>
        <router-view />
      </transition-page>
      <chatModal></chatModal>
    </div>
    <div class="landing-page-footer">
      <!-- <app-footer></app-footer> -->
    </div>
  </div>
</template>
<script>
import TransitionPage from "../../transitions/TransitionPage.vue";
import Header from "./appHeader.vue";
import Footer from "./appFooter.vue";
import chatModal from "../../components/chatModal";

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    TransitionPage,
    chatModal,
  },
  metaInfo() {
    return {
      title: "",
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      meta: [
        { property: "og:title", content: "KIPS LMS" },
        { property: "og:site_name", content: "Kips LMS" },
        { property: "og:type", content: "website" },
        { property: "og:url", content: process.env.VUE_APP_URL },
        { property: "og:description", content: "Break the shackles of time and space and learn wherever you are, whenever you feel like it." },
        { property: "og:image", content: `${process.env.VUE_APP_URL}/assets/images/cover-img.jpg` },
        { property: "og:image:secure", content: `${process.env.VUE_APP_URL}/assets/images/cover-img.jpg` },
        { property: "og:image:type", content: "image/jpg" },
      ],
    };
  },
  data() {
    return {
      scrolled: false,
      displayUI: true,
    };
  },

  mounted() {
    this.displayUI = true;
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
      // if(document.querySelector(".sticky-side-box")) {
      // var topLimit = document.querySelector(".sticky-side-box").offsetTop - 83;
      // if (topLimit <= window.scrollY) {
      //   document.querySelector(".sticky-side-box").classList.add("stickIt");
      // } else {
      //   document.querySelector(".sticky-side-box").classList.remove("stickIt");
      // }
      // }
    },
    gotToTop() {
      window.scrollTo(0, 0);
      window.location.hash = "";
    },
  },
  watch: {
    "$route.query"() {
      //alert(this.$route.hash)
      if (this.$route.hash) {
        var elm = this.$route.hash.slice(1);
        this.scrollToElement(elm);
      }
    },
  },
  created() {
    this.displayUI = true;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
