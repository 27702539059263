<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6" v-for="i in count" :key="i">
          <div class="exam-courses-loader">
            <div class="top-headings">
              <VueSkeletonLoader type="rect" :width="80" :height="12" rounded animation="fade" color="#dddddd" />
              <VueSkeletonLoader type="rect" :width="70" :height="12" rounded animation="fade" color="#dddddd" />
            </div>
            <div class="middle-lines">
              <VueSkeletonLoader type="rect" :width="500" :height="10" rounded animation="fade" color="#dddddd" />
              <VueSkeletonLoader type="rect" :width="500" :height="10" rounded animation="fade" color="#dddddd" />
              <VueSkeletonLoader type="rect" :width="500" :height="10" rounded animation="fade" color="#dddddd" />
            </div>
            <VueSkeletonLoader type="rect" :width="70" :height="12" rounded animation="fade" color="#dddddd" />
            <div class="bottom-tags">
              <VueSkeletonLoader type="circle" :width="12" :height="12" rounded animation="fade" color="#dddddd" />
              <VueSkeletonLoader type="rect" :width="70" :height="10" rounded animation="fade" color="#dddddd" />
              <VueSkeletonLoader type="rect" :width="70" :height="10" rounded animation="fade" color="#dddddd" />
            </div>
            <div class="bottom-buttons">
              <VueSkeletonLoader type="rect" :width="118" :height="33" animation="fade" color="#dddddd" />
              <VueSkeletonLoader type="rect" :width="118" :height="33" animation="fade" color="#dddddd" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["count"],
  components: {
    VueSkeletonLoader,
  },
};
</script>
<style>
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1440px) {
}
</style>
