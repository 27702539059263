<template>
  <div v-if="loadSkeleton">
    <component :is="id" :count="count"></component>
  </div>
</template>
<script>
import { eventHub } from "../../eventhub";
import programLoader from "./programLoader";
import examCoursesLoader from "./examCoursesLoader.vue";
import classCoursesLoader from "./classCoursesLoader.vue";
import boardLoader from "./boardLoader.vue";
export default {
  components: {
    programLoader,
    examCoursesLoader,
    classCoursesLoader,
    boardLoader,
  },
  data() {
    return {
      loadSkeleton: false,
    };
  },
  props: ["id", "count"],
  created() {
    eventHub.$on("before-request-" + this.id, (param) => {
      this.showSkeleton(param);
    });
    eventHub.$on("request-error-" + this.id, (param) => {
      this.hideSkeleton(param);
    });
    eventHub.$on("after-response-" + this.id, (param) => {
      this.hideSkeleton(param);
    });
    eventHub.$on("response-error-" + this.id, (param) => {
      this.hideSkeleton(param);
    });
  },
  methods: {
    showSkeleton(param) {
      this.loadSkeleton = true;
    },
    hideSkeleton(param) {
      this.loadSkeleton = false;
    },
  },
  beforeDestroy() {
    eventHub.$off("before-request-" + this.id, this.showSpinner);
    eventHub.$off("request-error-" + this.id, this.hideSpinner);
    eventHub.$off("after-response-" + this.id, this.hideSpinner);
    eventHub.$off("response-error-" + this.id, this.hideSpinner);
  },
};
</script>
